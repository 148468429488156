<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-07-30 15:25:25
-->

// 客户管理列表
<template>
  <div class="big_box">
    <TitleNav class="">
      <template #one>
        <span @click="go" class="cursor"> 首页 > </span>
      </template>
      <template #two>
        <span class="cursor"> 客户管理 </span>
      </template>
      <template v-slot:slotCon>
        <div class="background">
          <a-form layout="inline" :form="form" class="form">
            <a-form-item>
              <input
                placeholder="请填写客户姓名"
                class="userName"
                v-model="formData.name"
              />
            </a-form-item>
            <a-form-item>
              <input
                placeholder="请填写客户手机号"
                class="userName"
                v-model="formData.mobile"
              />
            </a-form-item>
            <button type="primary" class="submit" @click="submit">
              <span>搜索</span>
            </button>
          </a-form>
          <br />
          <div class="list" v-show="list != null">
            <table class="table">
              <tr>
                <th>序号</th>
                <th>客户姓名</th>
                <th>客户手机号</th>
                <th>客户身份证</th>
                <th>状态</th>
                <th>操作</th>
              </tr>
              <tr v-for="(list, index) in list" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ list.name ? list.name : null }}</td>
                <td>{{ list.mobile ? list.mobile : null }}</td>
                <td>{{ list.idCard ? list.idCard : null }}</td>
                <td
                  :class="
                    list.signStatus == '1'
                      ? 'se1'
                      : list.signStatus == '2'
                      ? 'se2'
                      : list.signStatus == '3'
                      ? 'se3'
                      : 'se'
                  "
                >
                  {{
                    list.signStatus == "1"
                      ? "已签约"
                      : list.signStatus == "2"
                      ? "未签约"
                      : list.status == "3"
                      ? "洽谈中"
                      : "合同临近"
                  }}
                </td>
                <td class="action">
                  <a @click="look(list.id, 'look')">查看</a>
                  <a-divider type="vertical" />
                  <a @click="look(list.id, 'talk',list.consumerId)">访谈</a>
                  <a-divider type="vertical" />
                  <a @click="look(list.id, 'clan')">合同</a>
                  <a-divider type="vertical" />
                  <a @click="look(list.id, 'pay')">支付</a>
                </td>
              </tr>
            </table>
          </div>

          <div class="blank" v-show="list == null">
            <div></div>
          </div>
        </div>
        <div class="paging">
          <a-pagination show-quick-jumper :total="total" @change="onChange" />
          <a-button class="button">GO </a-button>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽

import { getCustomerList } from "../api/api";

export default {
  name: "",
  data() {
    return {
      list: null,
      total: null,
      formData: {
        pageSize: 5,
      },
    };
  },

  components: { TitleNav, ebutton },
  props: {},
  computed: {},
  created() {
    this.submit();
  },
  methods: {
    // btnClick() {
    //   this.visible1 = true;
    // },
    // handleOk(e) {
    //   console.log(e);
    //   this.visible = false;
    //   this.visible1 = false;
    // },
    go() {
      this.$router.push("./home");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    submit() {
      //按条件查询提交
      let that = this;
      // console.log(this.formData);
      this.formData.pageNo = null;
      // this.$delete(this.formData, "pageNo");
      getCustomerList(this.formData).then((res) => {
        if (res.status == 200) {
          that.total = res.data.result.total;
          that.list = res.data.result.records;
          console.log(that.list), "99999999999999";
        }
      });
    },
    onChange(pageNumber) {
      //分页
      let that = this;
      this.formData.pageNo = pageNumber;
      getCustomerList(this.formData)
        .then((res) => {
          if (res.status == 200) {
            that.list = res.data.result.records;
            // console.log(that.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    look(id, type,consumerId) {
      this.$router.push({
        path: "/lookinfo",
        query: {
          id,
          type,
          consumerId
        },
      });
    },
  },
  watch: {},
};
</script>
<style scoped>
.big_box {
  position: relative;

  color: #666666;
}
.background {
  width: 1782px;
  height: 582px;
}
.form {
  float: right;
  margin-right: 249px;
}
.userName {
  width: 372px;
  height: 48px;
  border-radius: 8px;
  line-height: 48px;
  padding-left: 20px;
  border: 1px solid #ccc;
  font-size: 20px;
}

input:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #ccc;
}

.submit {
  cursor: pointer;
  width: 88px;
  height: 50px;
  background: #fea16e;
  border-radius: 10px;
}
:deep(.ant-divider-vertical) {
  border: 1px solid #cacaca;
  height: 32px;
  width: 1px;
}

.submit span {
  width: 40px;
  height: 20px;
  font-size: 20px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  margin-left: 1px;
}
.background .list {
  margin-top: 60px;
}
table {
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  font-size: 20px;
}
.background .list .table {
  width: 1535px;
  height: 124px;
  background-color: #ffffff;
}
.background .list .table tr {
  height: 74px;
}
.background .list .table th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #cfcfcf;
  background-color: #ffffff;
  text-align: center;
}

.background .list .table td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #cfcfcf;
  background-color: #ffffff;
  text-align: center;
}
.paging {
  width: 53%;
  margin-top: 85px;
  margin-bottom: 50px;
  float: right;
  display: flex;
}
.paging .button {
  margin-left: 10px;
  border-radius: 6px;
  background: #ffff;
}
a {
  color: rgba(235, 134, 68, 1);
}
.blank {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.se {
  color: #4197eb;
}
.se1 {
  color: #4fbc72;
}
.se2 {
  color: #cfcfcf;
}
.se3 {
  color: #eb8644;
}

/*==================================================*/

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
</style>
